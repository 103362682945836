﻿import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

let bodyLock = [];

bodyLock.$alwaysScrollable = document.querySelector('.js-panel');

function checkLock() {
    if ($('body').hasClass('panel-lock')) {
        disableBodyScroll(bodyLock.$alwaysScrollable);
    } else {
        clearAllBodyScrollLocks();
    }

    bodyLock.observerInstance = new MutationObserver(function (mutationsList, observer) {
        checkLock();
    });
}

function enable() {
    bodyLock.observerInstance.observe(document.querySelector('body'), { attributes: true });
    checkLock();
};

function disable() {
    bodyLock.observerInstance.disconnect();
    clearAllBodyScrollLocks();
};

$(document).on('breakpoint-match-charlie', function () {
    disable();
    $('html').removeClass('scroll-locked');
})

$(document).on('breakpoint-unmatch-charlie', function () {
    enable();
    if ($('.js-narrow-menu').hasClass('active')) {
        $('html').addClass('scroll-locked');
    }
})

checkLock();