﻿import '@/modules/Diagram.jQueryGlobal.js';
import { DiagramHasJS } from '@/modules/Diagram.hasJS.js';
import { DiagramAnalytics } from '@/modules/Diagram.Analytics.js'
import fitvids from '@/plugins/fitvids.js';
import DiagramBreakpoints from '@/modules/Diagram.Breakpoints.js';
import { DiagramForms } from '@/modules/Diagram.Forms.js';
import '@/modules/Diagram.BodyLock.js';

DiagramHasJS();

if (typeof pageTracker == "undefined") {
    var pageTracker = {};
}

function customGAEvent(category, action, label) {
    try {
        pageTracker._trackEvent(category, action, label);
    } catch (e) {
        // console.log(e);
    }
}

// Items that can be processed before/after images/css complete
$(document).ready(async function ($) {
    await DiagramAnalytics();
    await DiagramForms(); // technically this could be loaded asynchronously, but there's probably a form on every page (i.e. search form in header)
    await fitvids();

    if ($('.js-main-nav').length) {
        const { DiagramSiteNavigation } = await import('@/modules/Diagram.SiteNavigation.js');
        await DiagramSiteNavigation();
    }

    if ($('.js-select-nav').length) {
        const { DiagramSelectNav } = await import('@/modules/Diagram.SelectNav.js');
        await DiagramSelectNav();
    }

    if ($('.js-accordion, .js-section-nav').length) {
        const { DiagramAccordions } = await import('@/modules/Diagram.Accordions.js');
        await DiagramAccordions();
    }

    if ($('.js-tabs:not(.tabcordions)').length) {
        const { DiagramTabs } = await import('@/modules/Diagram.Tabs.js');
        await DiagramTabs();
    }

    if ($('.js-tabs.tabcordions').length) {
        const { DiagramTabcordions } = await import('@/modules/Diagram.Tabcordions.js');
        await DiagramTabcordions();
    }

    if ($('.gallery--masonry, .js-synchronized-sliders').length) {
        const { DiagramGallery } = await import('@/modules/Diagram.Gallery.js');
        await DiagramGallery();
    }

    if ($('.js-scrolling-hero').length) {
        const { DiagramScrollingHero } = await import('@/modules/Diagram.ScrollingHero.js');
        await DiagramScrollingHero();
    }

    if ($('.fancybox, .fancybox-media, .fancybox-modal, [data-fancybox]').length) {
        const { DiagramFancybox } = await import('@/modules/Diagram.Fancybox.js');
        await DiagramFancybox();
    }

    if ($('.js-page-select').length) {
        const { DiagramPagination } = await import('@/modules/Diagram.Pagination.js');
        await DiagramPagination();
    }

    if ($('.aa-wrap').length) {
        const { DiagramAppendAround } = await import('@/modules/Diagram.AppendAround.js');
        await DiagramAppendAround();
    }

    if ($('.alert-banners-wrapper').length) {
        const { DiagramAlertBanner } = await import('@/modules/Diagram.AlertBanner.js');
        await DiagramAlertBanner();
    }

    if ($('.sg-root').length) {
        const { DiagramStyleguide } = await import('@/modules/Diagram.Styleguide.js');
        await DiagramStyleguide();
    }

    await DiagramBreakpoints();
});

// This occurs after the page is done loading.
$(window).on("load", function () {
    if (typeof _gat != "undefined") {
        //pageTracker = _gat._createTracker('UA-11858386-2');
    }

    $('[data-ga-track]').each(function () {
        var $this = $(this);
        if ($this.attr('data-ga-category') != undefined && $this.attr('data-ga-event') != undefined && $this.attr('data-ga-label') != undefined) {
            customGAEvent($this.attr('data-ga-category'), $this.attr('data-ga-event'), $this.attr('data-ga-label'));
        }
    });
});